@font-face {
    font-family: 'SF-Pro-Display';
    src: url('../fonts/SFProDisplay-Medium.eot');
    src: url('../fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-Medium.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Medium.woff') format('woff'),
        url('../fonts/SFProDisplay-Medium.ttf') format('truetype'),
        url('../fonts/SFProDisplay-Medium.svg#SFProDisplay-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Black.eot');
    src: url('../fonts/Poppins-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Black.woff2') format('woff2'),
        url('../fonts/Poppins-Black.woff') format('woff'),
        url('../fonts/Poppins-Black.ttf') format('truetype'),
        url('../fonts/Poppins-Black.svg#Poppins-Black') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins-Med';
    src: url('../fonts/Poppins-Medium.eot');
    src: url('../fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins-Medium.ttf') format('truetype'),
        url('../fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF-Pro';
    src: url('../fonts/SFProDisplay-Regular.eot');
    src: url('../fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Regular.woff') format('woff'),
        url('../fonts/SFProDisplay-Regular.ttf') format('truetype'),
        url('../fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pop-L';
    src: url('../fonts/Poppins-Light.eot');
    src: url('../fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Light.woff2') format('woff2'),
        url('../fonts/Poppins-Light.woff') format('woff'),
        url('../fonts/Poppins-Light.ttf') format('truetype'),
        url('../fonts/Poppins-Light.svg#Poppins-Light') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

body {
  background-color: #000;
}

.InputElement {
    margin: 0 auto;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }


@tailwind base;
@tailwind components;
@tailwind utilities

